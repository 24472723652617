import axios from "axios";
import { endpoints } from "@/helpers/endpoints";

export const actions = {
  validarCotizacion: ({ commit }, payload) => {
    const path = `cotizaciones/verificar`;
    const formData = {
      nuevo: payload.data.nuevo,
      anio: payload.data.anio,
      tipo_documento: payload.data.tipo_documento,
      numero_documento: payload.data.numero_documento,
      patente: payload.data.patente,
      uso_producto: payload.data.uso_producto,
      uso_producto_descripcion: payload.data.uso_producto_descripcion,
      obj_marca: payload.data.obj_marca,
      obj_modelo: payload.data.obj_modelo,
    };
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "POST",
        data: formData,
      })
        .then((response) => {
          const result = response.data.data.cotizacion;
          commit("setQuotValidacionData", result);
          resolve(result);
        })
        .catch((err) => {
          commit("setQuotValidacionData", null);
          reject(err);
        });
    });
  },

  crearCotizacion: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `cotizaciones`,
        method: "POST",
        data: payload,
      })
        .then((response) => {
          const result = response.data.data;
          commit("setQuotNumber", result.cotizacion?.numero_cotizacion ?? 0);
          resolve(result);
        })
        .catch((e) => {
          const error = e.response.data;
          error.defaultError =
            "Ha ocurrido un error al intentar generar la simulación";
          reject(error);
        });
    });
  },

  GetCotizaciones({ commit }, params = {}) {
    const path = endpoints.quot.new;
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
        params: params,
      })
        .then((response) => {
          const { paginacion, cotizaciones } = response.data?.data;
          commit("SetQuots", cotizaciones);
          resolve({
            result: cotizaciones,
            pagination: paginacion,
          });
        })
        .catch((error) => {
          const rejectData = {
            info: "Ha ocurrido un error al intentar recuperar las cotizaciones.",
            result: error.response.data,
          };
          reject(rejectData);
        });
    });
  },
};

export default actions;
