import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    creatingQuotation: null,
    errorQuotation: false,
    errorRetake: null,
    retaking: null,
    quotation_validation: null,
    numberQuotation: null,
    offersQuotation: null,
    quotationState: null,
    cities: JSON.parse(sessionStorage.getItem("cities_one")) || {},
    cities_two: sessionStorage.getItem("cities_two") || null,
    payment_methods: sessionStorage.getItem("payment_methods") || [],
    blocked_by_payment_method: false,
    mpay2Blocked: false,
    buy_cart: sessionStorage.getItem("showBuyCart") || false,
    buy_cart_resume: sessionStorage.getItem("buy_cart_resume") || null,
    proposal_good: JSON.parse(sessionStorage.getItem("proposal_good")) || {},
    tarifa: sessionStorage.getItem("tarifa_selected") || null,
    creating: null,
    error_creating: false,
    beneficts: JSON.parse(sessionStorage.getItem("beneficts")) || [],
    mpay: sessionStorage.getItem("mpay") || "null",
    mpay2: sessionStorage.getItem("mpay2") || "null",
    InsurableInterestList: [],
    documentType: [],
    colors: [],
    dataProposalStep: null,
    proposal: sessionStorage.getItem("proposal_data") || null,
    step: 1,
    documents: {
      regular: [],
      pending: [],
      toppings: [],
    },
    companyLogo: {},
    greenSale: false,
    endosatarios: sessionStorage.getItem("endosatarios") || [],
  },
  actions,
  mutations,
  getters,
};
