export const idKeys = {
  channel: ":channelId",
  docId: ":docId",
  docType: ":docType",
  proposal: ":proposalId",
  baseUrl: ":baseUrl",
  mesosKey: `:mesosKey`,
};

export const endpoints = {
  auth: {
    base: "/auth",
    refresh: "/auth/refrescar-token",
    logout: "/auth/logout",
    // okta solo funciona en ambiente marsh
    // https://dev2.mclicksuite.com/marsh-mclick-bff-ventas/api/v1 -> https://dev2.mclicksuite.com/marsh-mclick-bff-ventas/loginOkta?x-mclick-key
    okta: `${
      idKeys.baseUrl.substring(
        0,
        idKeys.baseUrl.indexOf("marsh-mclick-bff-ventas")
      ) + "marsh-mclick-bff-ventas"
    }/loginOkta?x-mclick-key=${idKeys.mesosKey}`,
  },
  user: {
    base: "/usuarios",
    data: "/usuarios/data",
    passwordRecovery: "/usuarios/recuperar-clave",
    passwordChange: "/usuarios/clave",
  },
  quot: {
    base: "/cotizaciones",
    new: "/cotizaciones/pendientes",
  },
  client: {
    base: "/clientes",
    deals: `/clientes/RUT/${idKeys.docId}/ofertas`,
    products: `/clientes/RUT/${idKeys.docId}/productos`,
    quots: `/clientes/RUT/${idKeys.docId}/cotizaciones`,
    docs: `/clientes/RUT/${idKeys.docId}/documentos`,
  },
  channel: {
    generalInfo: "/canales/get-all/informacion-general",
    advanceInfo: "/canales/get-all/informacion-avanzada",
    advanceConfig: `/canales/${idKeys.channel}/configuracion-avanzada`,
  },
  country: {
    base: "/territorios/pais/codigo",
    docs: "/documentos/pais/codigo",
    locale: "/localidades/comunas",
  },
  product: {
    categories: `/ramos/all-services`,
  },
  business: {
    interest: "/interes-asegurables",
    docTypes: "/tipo-documentos",
    vehicleColors: "/colores-vehiculos",
    banks: "/bancos",
  },
  cart: {
    base: "/carrito",
  },
  proposal: {
    base: "/propuestas",
    step: `/propuestas/${idKeys.proposal}/step`,
    payment: `/propuestas/${idKeys.proposal}/medio-pago`,
    clientPayments: `/propuestas/RUT/${idKeys.docId}/${idKeys.proposal}/medio-pagos`,
    addPayment: `/propuestas/${idKeys.proposal}/agregar-medio-pago`,
    beneficiary: `/propuestas/${idKeys.proposal}/beneficiarios`,
    property: `/propuestas/${idKeys.proposal}/bien`,
    requirements: `/propuestas/${idKeys.proposal}/seccion/requisitos`,
    payer: `/propuestas/${idKeys.proposal}/pagador`,
    emailEdit: `/propuestas/${idKeys.proposal}/modificar-correo`,
    docs: `/documentos/${idKeys.proposal}/getAll`,
    signature: {
      digital: `propuestas/${idKeys.proposal}/firma/digital`,
      token: `propuestas/${idKeys.proposal}/firma/token`,
      documents: `propuestas/${idKeys.proposal}/firma/documentos`,
    },
  },
  payment: {
    base: "/medio-pagos",
    mpay: "/medio-pagos/mpay",
    mpay1V2: `medio-pagos/mpay/${idKeys.proposal}/editar`,
    mpay2: {
      base: "/medio-pagos/mpay2",
      status: `/medio-pagos/mpay2/${idKeys.proposal}/estado`,
      unsubscribe: `/medio-pagos/mpay2/${idKeys.proposal}/dar-de-baja`,
      policy: `/medio-pagos/mpay2/${idKeys.proposal}/obtener-poliza`,
    },
    pat: `propuestas/${idKeys.proposal}/medio-pago/PAT`,
    pac: `propuestas/${idKeys.proposal}/medio-pago/PAC`,
    cash: `propuestas/${idKeys.proposal}/pago/contado`,
  },
  inspection: {
    channel: "/inspecciones/canal/",
  },
  integration: {
    forum: `${process.env.VUE_APP_BASE_URL_INTEGRACION}?idIntension=${idKeys.proposal}&idTransaccion=${idKeys.docId}`,
  },
  endosatarios: {
    get: "/endosatarios/",
  },
};

export default endpoints;
